import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createBlog = createAsyncThunk('blog/create', async ({ state, dispatch, router }) => {
  try {
    const URL = `/blog/admin/new`;
    const response = await post(URL, state);
    if (response?.isSuccess) {
      router.push({
        pathname: paths.dashboard?.settings?.blogs,
        query: {},
      });
      dispatch(activeSnack({ type: 'success', message: 'Blog created successfully' }));
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllBlogs = createAsyncThunk(
  'blog/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/blog/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getBlogCounts = createAsyncThunk('blog/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/blog/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getBlogDetails = createAsyncThunk('blog/single', async ({ blogId, dispatch }) => {
  try {
    const response = await get(`blog/admin/single/${blogId}`);
    if (response?.isSuccess) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateBlogDetails = createAsyncThunk(
  'blog/update',
  async ({ state, blogId, dispatch, router }) => {
    try {
      const URL = `/blog/admin/${blogId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (router) {
          router.push({
            pathname: paths.dashboard?.settings?.blogs,
            query: {},
          });
        }
        dispatch(activeSnack({ type: 'success', message: 'Blog updated Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );

      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateBlogStatus = createAsyncThunk(
  'blog/status/update',
  async ({ state, blogId, dispatch }) => {
    try {
      const URL = `/blog/admin/status/active/${blogId}`;
      const response = await post(URL, state);

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archiveBlog = createAsyncThunk(
  'blog/archive',
  async ({ blogId, dispatch, handleClose }) => {
    try {
      const response = await post(`/blog/admin/delete/${blogId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Blog archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restoreBlog = createAsyncThunk(
  'blog/unarchive',
  async ({ blogId, dispatch, handleClose }) => {
    try {
      const response = await post(`/blog/admin/restore/${blogId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Blog Restored' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteBlog = createAsyncThunk(
  'blog/delete',
  async ({ blogId, dispatch, handleClose }) => {
    try {
      const response = await del(`/blog/admin/hard-delete/${blogId}/`);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Blog deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
