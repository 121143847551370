import { createAsyncThunk } from '@reduxjs/toolkit';

// import { paths } from 'src/routes/routes/paths';

import { get, post } from '../http';
import { activeSnack } from '../store/common';

export const getSettingsDetails = createAsyncThunk('settings/details', async ({ dispatch }) => {
  try {
    const response = await get(`/settings/admin/all`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateSettingsDetails = createAsyncThunk(
  'settings/update',
  async ({ state, dispatch }) => {
    try {
      const URL = `/settings/admin/update `;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(getSettingsDetails({ dispatch }));
        dispatch(activeSnack({ type: 'success', message: 'Settings updated Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );

      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
