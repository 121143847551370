import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @mui
import { Stack, Button, Container, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';
import DashboardLoading from 'src/components/loading/DashboardLoading';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardAdminApp() {
  // const { enqueueSnackbar } = useSnackbar();
  // const dispatch = useDispatch();
  // const [selectedYear, setSelectedYear] = useState('2024');

  const { dashLoading } = useSelector((state) => ({
    dashLoading: state.dashboard.loading,
  }));

  // useEffect(() => {
  //   const credentials = {
  //     enqueueSnackbar,
  //   };
  //   dispatch(getDashboardData(credentials));
  // }, [dispatch, enqueueSnackbar]);

  // useEffect(() => {
  //   const credentialsYear = {
  //     enqueueSnackbar,
  //     year: selectedYear,
  //   };

  //   dispatch(getPartnerEarningChartData(credentialsYear));
  //   // dispatch(getPartnerSalesChartData(credentialsYear));
  // }, [dispatch, enqueueSnackbar, selectedYear]);

  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Dashboard" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        {!dashLoading ? (
          <Stack alignItems="center" justifyContent="center" minHeight="70vh">
            <Typography>
              The Dashboard Panel is currently under development. You may access the Inventory
              sections for updates.{' '}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
              <Link to={paths.dashboard.inventory.root}>
                <Button variant="contained" size="small" color="success" sx={{ mt: 2 }}>
                  Visit Inventory Panel
                </Button>
              </Link>
              <Link to={paths?.public?.release_note}>
                <Button variant="outlined" size="small" color="success" sx={{ mt: 2 }}>
                  Release Note V2
                </Button>
              </Link>
            </Stack>
            {/* <DashboardContent setSelectedYear={setSelectedYear} selectedYear={selectedYear} /> */}
          </Stack>
        ) : (
          <DashboardLoading />
        )}
      </Container>
    </>
  );
}
