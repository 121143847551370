import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createReview = createAsyncThunk(
  'review/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/review/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        router.push({
          pathname: paths.dashboard?.reviews,
          query: {},
        });
        dispatch(activeSnack({ type: 'success', message: 'Review created successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllReviews = createAsyncThunk(
  'review/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/review/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getReviewCounts = createAsyncThunk('review/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/review/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getReviewDetails = createAsyncThunk(
  'review/single',
  async ({ reviewId, dispatch }) => {
    try {
      const response = await get(`review/admin/single/${reviewId}`);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateReviewDetails = createAsyncThunk(
  'review/update',
  async ({ state, reviewId, dispatch, router }) => {
    try {
      const URL = `/review/admin/${reviewId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (router) {
          router.push({
            pathname: paths.dashboard?.reviews,
            query: {},
          });
        }
        dispatch(activeSnack({ type: 'success', message: 'Review updated Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );

      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateReviewStatus = createAsyncThunk(
  'review/status/update',
  async ({ state, reviewId, dispatch }) => {
    try {
      const URL = `/review/admin/status/active/${reviewId}`;
      const response = await post(URL, state);

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status updated successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archiveReview = createAsyncThunk(
  'review/archive',
  async ({ reviewId, dispatch, handleClose }) => {
    try {
      const response = await post(`/review/admin/delete/${reviewId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Review archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restoreReview = createAsyncThunk(
  'review/unarchive',
  async ({ reviewId, dispatch, handleClose }) => {
    try {
      const response = await post(`/review/admin/restore/${reviewId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Review Restored' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteReview = createAsyncThunk(
  'review/delete',
  async ({ reviewId, dispatch, handleClose }) => {
    try {
      const response = await del(`/review/admin/hard-delete/${reviewId}/`);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Review deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
